// general functions
$(window).on('load', function() {showMessageOnIE()});

// components
if ($('.liSelect').length) {
    $('.liSelect select').on('focusin', function () {
        $(this).parent('.liSelect').addClass('is-active');
    });
    $('.liSelect select').on('focusout', function () {
        $(this).parent('.liSelect').removeClass('is-active');
    });
}

// nav
if($('.l-nav').length) {
    // functions
    function showMask() {
        el.mask.addClass(classActive);
    }
    function hideMask() {
        el.mask.removeClass(classActive);
    }
    function closeAllSubMenus() {
        el.liWithOneLevelSubMenu.parent('li').removeClass(classActive);
        el.liWithTwoLevelsSubMenu.parent('li').removeClass(classActive);
    }
    function overflowBlocked() {
        el.body.addClass('u-overflowBlocked');
    }
    function overflowFree() {
        el.body.removeClass('u-overflowBlocked');
    }

    // vars
    var scrollInit = 0;
    var classActive = 'is-active';
    var el = {
        body: $('body'),
        hamburguer: $('.c-hamburguer'),
        mask: $('.l-nav__maskMenu'),
        nav: $('.l-nav'),

        class_up: 'scrolledUp',
        class_down: 'scrolledDown',
        class_middle: 'scrolledMiddle',
        class_scroll: 'scrolled',
        class_hide: 'hidden',

        navContentHero: $('.l-nav__contentHero'),
        liWithOneLevelSubMenu: $('.liWithOneLevelSubMenu > a'),
        liWithTwoLevelsSubMenu: $('.liWithTwoLevelsSubMenu > a'),
        secondSubMenu: $('.secondSubMenu'),
        openSecondSubMenu: $('.openSecondSubMenu'),
    };

    // effect on scroll
    $(window).on('scroll', function(event) {
        var scrollBody = $(this).scrollTop();

        // scroll up to 99
        if (scrollBody > 99) {
            el.nav.addClass(el.class_scroll);
        } else {
            el.nav.removeClass(el.class_scroll);}

        // middle class
        if (scrollBody > 600) {
            el.nav.addClass(el.class_hide);
            el.nav.addClass(el.class_middle);
        } else {
            el.nav.removeClass(el.class_hide);
            el.nav.removeClass(el.class_middle);}

        // scroll up or down
        if (scrollBody < scrollInit) {
            el.nav.removeClass(el.class_hide);
            el.nav.addClass(el.class_up);
            el.nav.removeClass(el.class_down);
        } else {
            el.nav.removeClass(el.class_up);
            el.nav.addClass(el.class_down);
        }

        // close menus on hidden nav
        if(el.nav.hasClass('hidden')) {
            closeAllSubMenus();
            hideMask();
        }

        // reference var
        scrollInit = scrollBody;
    });

    // open/close/menus/submenus/hamburguer
    el.hamburguer.on('click', function() {
        if (is.mobile()) {
            // nothing
        }
        if ($(this).hasClass(classActive)) {
            overflowFree();
        } else {
            overflowBlocked();
        }
        $(this).toggleClass(classActive);
        el.mask.toggleClass(classActive);
        el.navContentHero.toggleClass(classActive);
    });
    el.mask.on('click', function() {
        hideMask();
        overflowFree();
        if (is.mobile()) {
            // nothing
        } else {
            closeAllSubMenus();
        }
        el.navContentHero.removeClass(classActive);
        el.hamburguer.removeClass(classActive);
    });

    // open/close with swipe
    if(is.touchDevice()) {
        document.addEventListener('touchstart', handleTouchStart, false);
        document.addEventListener('touchmove', handleTouchMove, false);
        var xDown = null;
        var yDown = null;
        function getTouches(evt) {
            // browser API
            return evt.touches ||
                // jQuery
                evt.originalEvent.touches;
        }
        function handleTouchStart(evt) {
            const firstTouch = getTouches(evt)[0];
            xDown = firstTouch.clientX;
            yDown = firstTouch.clientY;
        }
        function handleTouchMove(evt) {
            if ( ! xDown || ! yDown ) {
                return;
            }

            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;
            var xDiff = xDown - xUp;
            var yDiff = yDown - yUp;

            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
                if (xDiff > 0) {
                    // left swipe
                    toggleHamburguer_swiperLeft();
                } else {
                    // right swipe
                    toggleHamburguer_swiperRight();
                }
            } else {
                // if ( yDiff > 0 ) {
                //     // up swipe
                // } else {
                //     // down swipe
                // }
            }
            // reset
            xDown = null;
            yDown = null;
        }
        function toggleHamburguer_swiperRight() {
            el.hamburguer.removeClass(classActive);
            el.mask.removeClass(classActive);
            el.navContentHero.removeClass(classActive);
            //el.nav.removeClass('hidden');
            overflowFree();
            hideMask();
            console.log('right');
        }
        function toggleHamburguer_swiperLeft() {
            el.hamburguer.addClass(classActive);
            el.mask.addClass(classActive);
            el.navContentHero.addClass(classActive);
            el.nav.removeClass('hidden');
            overflowBlocked();
            showMask();
            console.log('left');
        }
    }

    // for navs with sub menus
    if ($('.l-nav--withSubMenus').length) {
        // open close submenus with click
        el.liWithOneLevelSubMenu.on('click', function (event) {
            event.preventDefault();
            if (is.mobile()) {
                // mobile
                $(this).parent('li').toggleClass(classActive);
                $(this).parent('li').children('a').toggleClass(classActive);
                showMask();
            } else {
                // desktop
                el.liWithOneLevelSubMenu.parent('li').removeClass(classActive);
                el.liWithTwoLevelsSubMenu.parent('li').removeClass(classActive);
                $(this).parent('li').addClass(classActive);
                showMask();
            }
        });
        el.liWithTwoLevelsSubMenu.on('click', function (event) {
            event.preventDefault();
            if (is.mobile()) {
                // mobile
                $(this).parent('li').toggleClass(classActive);
                $(this).parent('li').children('a').toggleClass(classActive);
                showMask()
            } else {
                // desktop
                el.liWithOneLevelSubMenu.parent('li').removeClass(classActive);
                el.liWithTwoLevelsSubMenu.parent('li').removeClass(classActive);
                $(this).parent('li').addClass(classActive);
                showMask()
            }
        });
        el.openSecondSubMenu.on('click', function(event) {
            event.preventDefault();
            if (is.mobile()) {
                $(this).children('.secondSubMenu').toggleClass(classActive);
            } else {
                // desktop
                el.secondSubMenu.removeClass(classActive);
                $(this).children('.secondSubMenu').addClass(classActive);
            }

        });
    }
}

$(document).on('change','#formNovidades_estado',function(){
    var uf = $(this).val();
    $("#formNovidades_cidade").html('<option>Carregando cidades...</option>');
    $.ajax({
        type: "POST",
        data: {uf: uf},
        url: "ajax-cidades.php",
        success: function (result) {
            $("#formNovidades_cidade").html(result);
        }
    });
});

// general input masks
$('.foneMask').focusout(function () {
    var phone, element;
    element = $(this);
    element.unmask();
    phone = element.val().replace(/\D/g, '');
    if (phone.length > 10) {
        element.mask("(99) 99999-999?9");
    } else {
        element.mask("(99) 9999-9999?9");
    }
}).trigger('focusout');

$('#btnSearchCod').on('click', function() {
    var button = $(this);
    var cod = $('#formNovidades_cod').val();
    if(cod !== '' && cod !== undefined){
        button.html('Enviando...');
        $.ajax({
            type: "POST",
            data: {codVerificacao: cod},
            url: "ajax-codVerificacao.php",
            success: function (result) {
                result = result.split('|');
                if(parseInt(result[1]) === 1){
                    button.html('Verificar').addClass('u-dnone');
                    $('#formNovidades_cod').attr('readonly','readonly');
                    $('.js-fullForm').removeClass('u-dnone');
                }else{
                    $('#formInscricao .status-envio').show();
                    $('#formInscricao .status-envio').html(result[2]);
                    setTimeout(function () {
                        $('#formInscricao .status-envio').hide();
                        $('#formInscricao .status-envio').empty();
                        $('#formNovidades_cod').removeAttr('readonly');
                        button.html('Verificar').removeClass('u-dnone');
                    }, 3000);

                }
            }
        });
    }
});

if ($('#formInscricao').length) {
    $("#formInscricao").validate({
        submitHandler: function (form) {
            //SUBMIT form
            $(form).ajaxSubmit({
                //target: 'status-envio',
                beforeSubmit: function () {
                    $('#formInscricao .status-envio').show();
                    $('#formInscricao .status-envio').html('<span class="c-stsSend__feedback c-stsSend__feedback--load"> Enviando, aguarde...</span>');
                    $('#btnInscricao').attr('disabled', true);
                },
                success: function (result) {
                    result = result.split('|');
                    if(parseInt(result[0].charAt(result[0].length-1)) == 1){
                        $('#formInscricao .status-envio').html(result[1]);
                        setTimeout(function(){
                            $('#btnInscricao').attr('disabled',false);
                            $("#formInscricao .field").val('');
                            $('#formInscricao .input[type="radio"]').prop('checked',false);
                            $("#formInscricao .field").removeClass('error');
                            $('.js-fullForm').addClass('u-dnone');
                            $('#btnSearchCod').html('Verificar').removeClass('u-dnone');
                            $('#formNovidades_cod').removeAttr('readonly');
                            $('#formInscricao .status-envio').hide();
                            $('#formInscricao .status-envio').empty();
                            goToSection__scroll($('#inscricao'), -50)
                        },2000);
                    }else{
                        $('#formInscricao .status-envio').html(result);
                        setTimeout(function () {
                            $('#formInscricao .status-envio').hide();
                            $('#formInscricao .status-envio').empty();
                            $('#btnInscricao').attr('disabled', false);
                        }, 3000);
                    }
                }
            });
        }
    });

    $('.js-transferS > *').on('click', function() {
        $('.js-moreOptionsTransfer').removeClass('u-dnone');
    });
    $('.js-transferN > *').on('click', function() {
        $('.js-moreOptionsTransfer').addClass('u-dnone');
    });
}

if ($('#formDuvida').length) {
    $("#formDuvida").validate({
        submitHandler: function (form) {
            //SUBMIT form
            $(form).ajaxSubmit({
                //target: 'status-envio',
                beforeSubmit: function () {
                    $('#formDuvida .status-envio').show();
                    $('#formDuvida .status-envio').html('<span class="c-stsSend__feedback c-stsSend__feedback--load"> Enviando, aguarde...</span>');
                    $('#btnDuvida').attr('disabled', true);
                },
                success: function (result) {
                    result = result.split('|');
                    if(parseInt(result[0].charAt(result[0].length-1)) == 1){
                        $('#formDuvida .status-envio').html(result[1]);
                        setTimeout(function(){
                            $('#btnDuvida').attr('disabled',false);
                            $("#formDuvida .field").val('');
                            $("#formDuvida textarea").val('').html('');
                            $("#formDuvida .field").removeClass('error');
                            $('#formDuvida .status-envio').hide();
                            $('#formDuvida .status-envio').empty();
                        },2000);
                    }else{
                        $('#formInscricao .status-envio').html(result);
                        setTimeout(function () {
                            $('#formDuvida .status-envio').hide();
                            $('#formDuvida .status-envio').empty();
                            $('#btnDuvida').attr('disabled', false);
                        }, 3000);
                    }
                }
            });
        }
    });
}